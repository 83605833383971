import React from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

// Регистрация шкал и элементов
ChartJS.register(CategoryScale, LinearScale, BarElement,ArcElement, Title, Tooltip, Legend);

const OfferStatistics = ({ offerData }) => {
    const { offer_name, total_users, total_payments, total_registrations, total_amount, partner_earnings } = offerData;

    const userGraphData = {
        labels: [ 'Оплаты', 'Регистрации'],
        datasets: [
            {
                // label: `${offer_name}`,
                data: [total_payments, total_registrations],
                backgroundColor: ['rgba(255, 206, 86, 0.5)', 'rgba(54, 162, 235, 0.5)'],
            },
        ],
    };

    // const financialGraphData = {
    //     labels: ['Сумма Платежей', 'Заработок Партнёра'],
    //     datasets: [
    //         {
    //             label: `Финансовые данные для ${offer_name}`,
    //             data: [total_amount, partner_earnings],
    //             backgroundColor: ['rgba(255, 99, 132, 0.5)', 'rgba(201, 203, 207, 0.5)'],
    //         },
    //     ],
    // };

    const options = {
        responsive: true,
        maintainAspectRatio: false, // Отключение сохранения пропорций, чтобы CSS-контейнер управлял размерами
        rotation: 0, // Поворачиваем круг на 180 градусов
    circumference: 360, // Полный круг // Полный круг/ Определяет угол отображения данных (например, 180° для полукруга)
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    font: {
                        size: 10 // Уменьшение размера шрифта для легенды
                    },
                    boxWidth: 10, // Размер квадрата цвета в легенде
                    padding: 10 // Отступы между элементами в легенде
                },
            },
            // title: {
            //     display: true,
            //     // text: `${offerData.offer_name}`,
            //     font: {
            //         size: 14 // Уменьшение размера шрифта заголовка
            //     },
            //     padding: { top: 10, bottom: 20 }, // Отступы заголовка
            // },
            tooltip: {
                bodyFont: {
                    size: 10 // Уменьшение размера шрифта всплывающих подсказок
                },
            },
        },
        layout: {
            padding: {
                left: 5,
                right: 5,
                top: 5,
                bottom: 5
            }
        },
    };
    
    return (
        <div>
            <h3>{offer_name}</h3>
            <div style={{ display: 'flex', gap: '20px', flexDirection: 'row' }}>
                <div style={{ width: '150px', height: '150px' }}>
                    <Pie data={userGraphData} options={options} />
                </div>
                {/* <div style={{ width: '50%' }}>
                    <Bar data={financialGraphData} options={options} />
                </div> */}
            </div>
        </div>
    );
};

export default OfferStatistics;
