import React, { useState } from 'react';

import '../styles/test.css';

const Test = () => {
      

    const [activeTab, setActiveTab] = useState('План');

    const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    };

    return (
    <div className="dashboard">
        <aside className="sidebar">
        <img src="logo.png" alt="Five Ads Logo" className="logo" />
        <ul>
            <li>Личный кабинет</li>
            <li>Авторизация</li>
            <li>Финансы</li>
        </ul>
        </aside>
        <div>
        <main className="content">
        <header className="header">
            <input type="text" placeholder="Поиск по названию" className="search-input" />
            <div className="filters">
            <select>
                <option>Выбор Аккаунта</option>
            </select>
            

            <input type="text" placeholder="Тэг" />
            <input type="text" placeholder="Цена от" />
            <input type="text" placeholder="Цена до" />
            <select>
                <option>Категория</option>
            </select>
            </div>
            <div className="extra-filters">
            <label>
                <input type="checkbox" /> Искать только в названиях
            </label>
            <button>Сбросить фильтры</button>
            </div>
        </header>

        <div className="tables">
            <section className="filter-table">
            <h2>Таблица Связи по Таблицам</h2>
            <div className="tabs">
                {['Метрика', 'Свод', 'Настройки', 'Таблица'].map((tab) => (
                <button
                    key={tab}
                    className={`tab ${activeTab === tab ? 'active' : ''}`}
                    onClick={() => handleTabClick(tab)}
                >
                    {tab}
                </button>
                ))}
            </div>
            <table>
                <thead>
                <tr>
                    <th>Разделы</th>
                    <th>План</th>
                    <th>Сегодня</th>
                    <th>Вчера</th>
                    <th>Месяц</th>
                    <th>Всего</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Пример</td>
                    <td>1524 / 1550</td>
                    <td>1234 / 150</td>
                    <td>1524 / 400</td>
                    <td>1524 / 600</td>
                    <td>1524 / 688</td>
                </tr>
                {/* Повторите строки, если нужно */}
                </tbody>
            </table>
            </section>

            <section className="settings-table">
            <h2>Настройки Связи по Таблицам</h2>
            <div className="table-item">
                <h3>Таблица 1</h3>
                <label>Знач. поля</label>
                <input type="text" />
                <button>Сохранить</button>
            </div>
            <div className="table-item">
                <h3>Таблица Google</h3>
                <label>Google API</label>
                <input type="text" />
                <button>Сохранить</button>
            </div>
            </section>

            <section className="personal-data">
            <h2>Личные данные</h2>
            <label>Новый пароль</label>
            <input type="password" />
            <button>Применить</button>
            </section>
        </div>
        </main>

        <footer className="footer">
        <p>AVi-TOK INC. © COPYRIGHT 2023. ALL RIGHTS RESERVED</p>
        <div className="footer-links">
            <a href="#privacy">Политика конфиденциальности</a>
            <a href="#offer">Оферта</a>
            <a href="#prices">Цены</a>
        </div>
        </footer>
        </div>
    </div>
    );
};

    
export default Test;
