import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faHeart, faBars, faHome, faSignIn, faSignOut } from '@fortawesome/free-solid-svg-icons'; // Пример иконок из FontAwesome
import s from '../styles/Navbar.module.css'; // Подключение стилей
import { useNavigate } from 'react-router-dom';
import MatchesIcon from './Icons';

function Navbar() {
  const { token, currentUser } = useContext(AuthContext); // Получаем текущее состояние пользователя 
  const { currentUserProfile } = useContext(AuthContext);
  
  const navigate = useNavigate();

  return (
    <nav className={s.navbar}>
      {/* Левая часть с текстом */}
        {/* <img className={s.lovesekrets} src={`${process.env.PUBLIC_URL}/lovesekrets2.png`} alt='lovesekrets'/> */}
      <div className={s.lovesekrets} onClick={() => navigate('/search')}>TopTopAff.com</div>

      {/* Правая часть с иконками */}
      <ul className={s.navbar_links}>
        {currentUser && currentUserProfile && !token ? (
          <>
            {/* Отображаем иконки для авторизованного пользователя */}
            <li>
              <Link to="/ChatList" title="Chats">
                <FontAwesomeIcon icon={faComments} />
              </Link>
            </li>
            <li>
              <Link to="/WhoLike" title="Likes">
                <FontAwesomeIcon icon={faHeart} />
              </Link>
            </li>
            <li>
              <Link to="/matches" title="Matches">
              <MatchesIcon /> {/* Вставка вашей SVG-иконки */}
              </Link>
            </li>
            <li>
              <Link to="/Menu" title="Menu">
                <FontAwesomeIcon icon={faBars} />
              </Link>
            </li>
          </>
        ) : currentUser && !currentUserProfile && !token ? (
          <>
            {/* Отображаем иконки для неавторизованного пользователя */}
            <li>
              <Link to="/logout" title="Logout">
              <FontAwesomeIcon icon={faSignOut} />
              </Link>
            </li>
          </>
          ) : token ? (
            <>
            {/* Отображаем иконки для авторизованного пользователя */}
            <li>
              <Link to="/ChatList" title="Chats">
                <FontAwesomeIcon icon={faComments} />
              </Link>
            </li>
            <li>
              <Link to="/WhoLike" title="Likes">
                <FontAwesomeIcon icon={faHeart} />
              </Link>
            </li>
            <li>
              <Link to="/matches" title="Matches">
              <MatchesIcon /> {/* Вставка вашей SVG-иконки */}
              </Link>
            </li>
            <li>
              <Link to="/Menu" title="Menu">
                <FontAwesomeIcon icon={faBars} />
              </Link>
            </li>
          </>

        ) : (
          <>
            {/* Отображаем иконки для неавторизованного пользователя */}
            <li>
              <Link to="/" title="Home">
              <FontAwesomeIcon icon={faHome} />
              </Link>
            </li>
            <li>
              <Link to="/login" title="Login">
              <FontAwesomeIcon icon={faSignIn} />
              </Link>
            </li>
            {/* <li>
              <Link to="/register" title="Register">
              <FontAwesomeIcon icon={faJoi} />
              </Link>
            </li> */}
            <li>
              <button className={s.button} onClick={() => navigate('/register')}>Try Now</button>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
}

export default Navbar;
