import React, { createContext, useState, useEffect } from 'react';
// import { Navigate } from 'react-router-dom';

// Создаем контекст
export const AuthContext = createContext();

// Провайдер контекста
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserProfile, setCurrentUserProfile] = useState(null);
  const [token, setToken] = useState(null);

  // Функция для получения текущего пользователя
  const fetchCurrentUser = async () => {
    try {
      const response = await fetch('/api/current_user', {
        credentials: 'include',  // Включаем куки для сессии
      });
  
      const data = await response.json();
      
      if (!data.authorized) {
        setCurrentUser(null);
      setCurrentUserProfile(null);  // Сбрасываем профиль
        // Пользователь не авторизован
        console.log('User is not authorized');
        
        // setCurrentUser(null);
      } else { if (!data.userprofile) {
        // Пользователь авторизован
        setCurrentUserProfile(null);
        setCurrentUser(data);
        console.log('User is not profile');
        
      } else {
        // Пользователь авторизован
        setCurrentUser(data);
        setCurrentUserProfile(data.userprofile);
        console.log(data.userprofile);
      } 
      }
      
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };

  // Функция для аутентификации через Telegram
  const authenticateTelegramUser = async () => {
    try {
      if (typeof window.Telegram !== "undefined" && window.Telegram.WebApp) {
        const initData = window.Telegram.WebApp.initData;
        window.Telegram.WebApp.expand();
        // window.Telegram.WebApp.disableClosingConfirmation();
        window.Telegram.WebApp.isVerticalSwipesEnabled = false;
        console.log('initData:', initData); 
        const response = await fetch('/api/auth/telegram', {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ initData }),  // Передаем initData в теле запроса
        });

        if (response.ok) {
          const data = await response.json();
          const jwtToken = data.token;
          

          // Сохраняем JWT в localStorage
          localStorage.setItem('jwtToken', jwtToken);
          setToken(jwtToken);
          console.log('JWT token сохранен в localStorage');
        } else {
          const errorData = await response.json();
          console.error('Ошибка авторизации:', errorData.error);
          // Обработка ошибки при необходимости
        }
      } else {
        console.error("Telegram Web App API не найден. Откройте приложение через Telegram.");
      }
    } catch (error) {
      console.error('Ошибка запроса:', error);
      // Обработка ошибки при необходимости
    }
  };
   

  // Выполняем получение текущего пользователя при монтировании компонента
  useEffect(() => {
    fetchCurrentUser();
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, fetchCurrentUser, setCurrentUser, currentUserProfile, setCurrentUserProfile, token, authenticateTelegramUser }}>
      {children}
    </AuthContext.Provider>
  );
};
