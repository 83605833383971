import React from 'react';
import { useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext'; // Импортируем AuthProvider
import { DateProvider } from './DateContext';
import { useTranslation } from 'react-i18next';
import Home from './components/Home';
import Logout from './components/Logout';
import Login from './components/Login';
import Register from './components/Register';
import Navbar from './components/Navbar';  // Импорт Navbar
import CreateProfile from './components/CreateProfile';
import Profile from './components/Profile';
import EditProfile from './components/EditProfile';
import Menu from './components/Menu.jsx';
import TelegramWebApp from './components/TelegramWebApp.jsx';
import PartnerDashboard from './components/PartnerDashboard.js';
import PartnerStatistics from './components/PartnerStatistics.js';
import PartnerStatisticsTable from './components/PartnerStatisticsTable.jsx';
import Test from './components/test';

import './components/i18n';
import './styles/all.css';
import './App.css'

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const { i18n } = useTranslation();

   // Восстанавливаем язык при загрузке приложения
   useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'ru'; // По умолчанию русский
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);

  // Прочитать тему из localStorage при первой загрузке приложения
  useEffect(() => {
    const savedTheme = localStorage.getItem('darkMode');
    if (savedTheme === 'true') {
      setDarkMode(true);
      document.documentElement.classList.add('dark_mode');
    }
  }, []);


  // Функция для переключения темы
  const toggleTheme = () => {
    setDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem('darkMode', newMode);
      if (newMode) {
        document.documentElement.classList.add('dark_mode');
      } else {
        document.documentElement.classList.remove('dark_mode');
      }
      return newMode;
    });
  };




  return (
    <AuthProvider>
      <div className='body'>
      <DateProvider>
        <Router>
        <Routes>
           <Route path='/test' element={<Test/>} />
        </Routes>
        {/* <Header /> */}
          {/* <Navbar />  */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/register" element={<Register />} />
            <Route path="/CreateProfile" element={<CreateProfile />} />
            <Route path='/login' element={<Login />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/edit_profile" element={<EditProfile />} /> 
            <Route path='/Menu' element={<Menu />} />
            <Route path="/Menu" element={<Menu whiteMode={darkMode} toggleTheme={toggleTheme} />} />
            <Route path='/telegram' element={<TelegramWebApp/>} />
            
                <Route path='/partner' element={<PartnerDashboard/>} />
                <Route path='/partnerst' element={<PartnerStatistics/>} />
                <Route path='/partnersttable' element={<PartnerStatisticsTable/>} />
            
          </Routes>
        </Router>
        </DateProvider>
      </div>
      
  </AuthProvider>
    
  );
}

export default App;
