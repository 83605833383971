// import React, { useState, useEffect, useCallback } from 'react';
// import axios from 'axios';

// const PartnerStatistics = () => {
//     const [statistics, setStatistics] = useState([]);
//     const [totals, setTotals] = useState({});
//     const [startDate, setStartDate] = useState('');
//     const [endDate, setEndDate] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);

//     // Функция для получения статистики с сервера
//     const fetchStatistics = useCallback(async () => {
//         setLoading(true);
//         setError(null);
        
//         try {
//             // Создаем параметры запроса
//             const params = {};
//             if (startDate) params.start_date = startDate;
//             if (endDate) params.end_date = endDate;

//             // Отправляем GET-запрос
//             const response = await axios.get('/api/partner/statistics', { params });
            
//             // Устанавливаем полученные данные в состояние
//             setStatistics(response.data.offers);
//             setTotals(response.data.totals)
//         } catch (err) {
//             setError('Не удалось загрузить статистику. Пожалуйста, попробуйте снова.');
//             console.error(err);
//         } finally {
//             setLoading(false);
//         }
//     }, [startDate, endDate]);
//     // Обработчик для обновления данных при изменении дат
//     const handleSubmit = (event) => {
//         event.preventDefault();
//         fetchStatistics();
//     };

//     useEffect(() => {
//         // Загружаем данные при первом рендере компонента
//         fetchStatistics();
//     }, [fetchStatistics]);

//     return (
//         <div className="partner-statistics">
//             <h2>Статистика Партнёра</h2>
//             <form onSubmit={handleSubmit}>
//                 <div>
//                     <label>Дата начала: </label>
//                     <input
//                         type="date"
//                         value={startDate}
//                         onChange={(e) => setStartDate(e.target.value)}
//                     />
//                 </div>
//                 <div>
//                     <label>Дата окончания: </label>
//                     <input
//                         type="date"
//                         value={endDate}
//                         onChange={(e) => setEndDate(e.target.value)}
//                     />
//                 </div>
//                 <button type="submit" disabled={loading}>
//                     {loading ? 'Загрузка...' : 'Обновить'}
//                 </button>
//             </form>

//             {error && <p style={{ color: 'red' }}>{error}</p>}

//             <div className="statistics-table">
//                 {loading ? (
//                     <p>Загрузка данных...</p>
//                 ) : (
//                     <table>
//                         <thead>
//                             <tr>
//                                 <th>ID Оффера</th>
//                                 <th>Название Оффера</th>
//                                 <th>Общее Кол-во Пользователей</th>
//                                 <th>Кол-во Оплат</th>
//                                 <th>Кол-во Регистраций</th>
//                                 <th>Общее Кол-во Действий</th>
//                                 <th>Общая Сумма</th>
//                                 <th>Заработок Партнёра</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {statistics.length > 0 ? (
//                                 <>
//                                     {statistics.map((stat) => (
//                                         <tr key={stat.offer_id}>
//                                             <td>{stat.offer_id}</td>
//                                             <td>{stat.offer_name}</td>
//                                             <td>{stat.total_users}</td>
//                                             <td>{stat.total_payments}</td>
//                                             <td>{stat.total_registrations}</td>
//                                             <td>{stat.total_actions}</td>
//                                             <td>{stat.total_amount.toFixed(2)}</td>
//                                             <td>{stat.partner_earnings.toFixed(2)}</td>
//                                         </tr>
//                                     ))}
//                                     {/* Добавляем итоговую строку */}
//                                     <tr>
//                                         <td colSpan="6"><strong>Итого</strong></td>
//                                         <td><strong>{totals.total_payments_amount.toFixed(2)}</strong></td>
//                                         <td><strong>{totals.total_partner_earnings.toFixed(2)}</strong></td>
//                                     </tr>
//                                 </>
//                             ) : (
//                                 <tr>
//                                     <td colSpan="8">Нет данных для отображения.</td>
//                                 </tr>
//                             )}
//                         </tbody>
//                     </table>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default PartnerStatistics;

// import React, { useState, useEffect, useCallback } from 'react';
// import axios from 'axios';
// import { Bar } from 'react-chartjs-2';
// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend
// } from 'chart.js';
// import s from '../styles/PartnerStatistics.module.css'

// // Регистрация компонентов Chart.js
// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const PartnerStatistics = () => {
//     const [statistics, setStatistics] = useState([]);
//     const [totals, setTotals] = useState({});
//     const [startDate, setStartDate] = useState('');
//     const [endDate, setEndDate] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);

//     // Функция для получения статистики с сервера
//     const fetchStatistics = useCallback(async () => {
//         setLoading(true);
//         setError(null);

//         try {
//             const params = {};
//             if (startDate) params.start_date = startDate;
//             if (endDate) params.end_date = endDate;

//             const response = await axios.get('/api/partner/statistics', { params });
//             setStatistics(response.data.offers);
//             setTotals(response.data.totals);
//         } catch (err) {
//             setError('Не удалось загрузить статистику. Пожалуйста, попробуйте снова.');
//             console.error(err);
//         } finally {
//             setLoading(false);
//         }
//     }, [startDate, endDate]);

//     // Обработчик для обновления данных при изменении дат
//     const handleSubmit = (event) => {
//         event.preventDefault();
//         fetchStatistics();
//     };

//     useEffect(() => {
//         // Загружаем данные при первом рендере компонента
//         fetchStatistics();
//     }, [fetchStatistics]);

//     // Подготовка данных для графика
//     const graphData = {
//         labels: statistics.map((stat) => stat.offer_name),
//         datasets: [
//             {
//                 label: 'Общее Кол-во Пользователей',
//                 data: statistics.map((stat) => stat.total_users),
//                 backgroundColor: 'rgba(54, 162, 235, 0.5)',
//             },
//             {
//                 label: 'Кол-во Оплат',
//                 data: statistics.map((stat) => stat.total_payments),
//                 backgroundColor: 'rgba(75, 192, 192, 0.5)',
//             },
//             {
//                 label: 'Кол-во Регистраций',
//                 data: statistics.map((stat) => stat.total_registrations),
//                 backgroundColor: 'rgba(255, 206, 86, 0.5)',
//             },
//             {
//                 label: 'Общее Кол-во Действий',
//                 data: statistics.map((stat) => stat.total_actions),
//                 backgroundColor: 'rgba(153, 102, 255, 0.5)',
//             },
//             {
//                 label: 'Общая Сумма Платежей',
//                 data: statistics.map((stat) => stat.total_amount),
//                 backgroundColor: 'rgba(255, 99, 132, 0.5)',
//             },
//             {
//                 label: 'Заработок Партнёра',
//                 data: statistics.map((stat) => stat.partner_earnings),
//                 backgroundColor: 'rgba(201, 203, 207, 0.5)',
//             },
//         ],
//     };

//     // Настройки графика
//     const options = {
//         responsive: true,
//         plugins: {
//             legend: {
//                 position: 'top',
//             },
//             title: {
//                 display: true,
//                 text: 'Полная Статистика Партнёра по Офферам',
//             },
//         },
//         scales: {
//             x: {
//                 stacked: false,
//             },
//             y: {
//                 stacked: false,
//             },
//         },
//     };

//     return (
//         <div className={s.partner_statistics}>
//             <h2>Статистика Партнёра</h2>
//             <form onSubmit={handleSubmit}>
//                 <div>
//                     <label>Дата начала: </label>
//                     <input
//                         type="date"
//                         value={startDate}
//                         onChange={(e) => setStartDate(e.target.value)}
//                     />
//                 </div>
//                 <div>
//                     <label>Дата окончания: </label>
//                     <input
//                         type="date"
//                         value={endDate}
//                         onChange={(e) => setEndDate(e.target.value)}
//                     />
//                 </div>
//                 <button type="submit" disabled={loading}>
//                     {loading ? 'Загрузка...' : 'Обновить'}
//                 </button>
//             </form>

//             {error && <p style={{ color: 'red' }}>{error}</p>}

//             <div className={s.statistics_chart}>
//                 {loading ? (
//                     <p>Загрузка данных...</p>
//                 ) : (
//                     <Bar data={graphData} options={options} />
//                 )}
//             </div>

//             <div className={s.statistics_table}>
//                 {loading ? (
//                     <p>Загрузка данных...</p>
//                 ) : (
//                     <table>
//                         <thead>
//                             <tr>
//                                 <th>ID Оффера</th>
//                                 <th>Название Оффера</th>
//                                 <th>Общее Кол-во Пользователей</th>
//                                 <th>Кол-во Оплат</th>
//                                 <th>Кол-во Регистраций</th>
//                                 <th>Общее Кол-во Действий</th>
//                                 <th>Общая Сумма</th>
//                                 <th>Заработок Партнёра</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {statistics.length > 0 ? (
//                                 <>
//                                     {statistics.map((stat) => (
//                                         <tr key={stat.offer_id}>
//                                             <td>{stat.offer_id}</td>
//                                             <td>{stat.offer_name}</td>
//                                             <td>{stat.total_users}</td>
//                                             <td>{stat.total_payments}</td>
//                                             <td>{stat.total_registrations}</td>
//                                             <td>{stat.total_actions}</td>
//                                             <td>{stat.total_amount.toFixed(2)}</td>
//                                             <td>{stat.partner_earnings.toFixed(2)}</td>
//                                         </tr>
//                                     ))}
//                                     <tr>
//                                         <td colSpan="6"><strong>Итого</strong></td>
//                                         <td><strong>{totals.total_payments_amount.toFixed(2)}</strong></td>
//                                         <td><strong>{totals.total_partner_earnings.toFixed(2)}</strong></td>
//                                     </tr>
//                                 </>
//                             ) : (
//                                 <tr>
//                                     <td colSpan="8">Нет данных для отображения.</td>
//                                 </tr>
//                             )}
//                         </tbody>
//                     </table>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default PartnerStatistics;

// import React, { useState, useEffect, useCallback } from 'react';
// import axios from 'axios';
// import OfferStatistics from './OfferStatistics';
// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend
// } from 'chart.js';
// import s from '../styles/PartnerStatistics.module.css';

// // Регистрация шкал и элементов
// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const PartnerStatistics = () => {
//     const [statistics, setStatistics] = useState([]);
//     const [startDate, setStartDate] = useState('');
//     const [endDate, setEndDate] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);

//     const fetchStatistics = useCallback(async () => {
//         setLoading(true);
//         setError(null);

//         try {
//             const params = {};
//             if (startDate) params.start_date = startDate;
//             if (endDate) params.end_date = endDate;

//             const response = await axios.get('/api/partner/statistics', { params });
//             setStatistics(response.data.offers);
//         } catch (err) {
//             setError('Не удалось загрузить статистику. Пожалуйста, попробуйте снова.');
//             console.error(err);
//         } finally {
//             setLoading(false);
//         }
//     }, [startDate, endDate]);

//     const handleSubmit = (event) => {
//         event.preventDefault();
//         fetchStatistics();
//     };

//     useEffect(() => {
//         fetchStatistics();
//     }, [fetchStatistics]);

//     return (
//         <div className={s.partner_statistics}>
//             <form onSubmit={handleSubmit}>
//                 <div>
//                     <label>От: </label>
//                     <input
//                         type="date"
//                         value={startDate}
//                         onChange={(e) => setStartDate(e.target.value)}
//                     />
//                 </div>
//                 <div>
//                     <label>До: </label>
//                     <input
//                         type="date"
//                         value={endDate}
//                         onChange={(e) => setEndDate(e.target.value)}
//                     />
//                 </div>
//                 <button type="submit" disabled={loading}>
//                     {loading ? 'Загрузка...' : 'Обновить'}
//                 </button>
//             </form>

//             {error && <p style={{ color: 'red' }}>{error}</p>}

//             <div className={s.statistics_container}>
//                 {loading ? (
//                     <p>Загрузка данных...</p>
//                 ) : (
//                     statistics.length > 0 ? (
//                         statistics.map((stat) => <OfferStatistics key={stat.offer_id} offerData={stat} />)
//                     ) : (
//                         <p>Нет данных для отображения.</p>
//                     )
//                 )}
//             </div>
//         </div>
//     );
// };

// export default PartnerStatistics;

// import React, { useState, useEffect, useCallback } from 'react';
// import axios from 'axios';
// import OfferStatistics from './OfferStatistics';
// import PartnerStatisticsTable from './PartnerStatisticsTable'; // Подключите таблицу
// import dayjs from 'dayjs';
// import s from '../styles/PartnerStatistics.module.css';

// // Регистрация Chart.js (предположим, она уже сделана)
// const PartnerStatistics = () => {
//     // Задаем начальную дату и конечную дату на последние 30 дней
//     const [startDate, setStartDate] = useState(dayjs().subtract(30, 'day').format('YYYY-MM-DD'));
//     const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));
//     const [statistics, setStatistics] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);

//     const fetchStatistics = useCallback(async () => {
//         setLoading(true);
//         setError(null);

//         try {
//             const params = { start_date: startDate, end_date: endDate };

//             const response = await axios.get('/api/partner/statistics', { params });
//             setStatistics(response.data.offers);
//         } catch (err) {
//             setError('Не удалось загрузить статистику. Пожалуйста, попробуйте снова.');
//             console.error(err);
//         } finally {
//             setLoading(false);
//         }
//     }, [startDate, endDate]);

//     const handleSubmit = (event) => {
//         event.preventDefault();
//         fetchStatistics();
//     };

//     useEffect(() => {
//         fetchStatistics();
//     }, [fetchStatistics]);

//     return (
//         <div className={s.partner_statistics}>
//             <form onSubmit={handleSubmit}>
//                 <div>
//                     <label>От: </label>
//                     <input
//                         type="date"
//                         value={startDate}
//                         onChange={(e) => setStartDate(e.target.value)}
//                     />
//                 </div>
//                 <div>
//                     <label>До: </label>
//                     <input
//                         type="date"
//                         value={endDate}
//                         onChange={(e) => setEndDate(e.target.value)}
//                     />
//                 </div>
//                 <button type="submit" disabled={loading}>
//                     {loading ? 'Загрузка...' : 'Обновить'}
//                 </button>
//             </form>

//             {error && <p style={{ color: 'red' }}>{error}</p>}

//             <div className={s.statistics_container}>
//                 {loading ? (
//                     <p>Загрузка данных...</p>
//                 ) : (
//                     statistics.length > 0 ? (
//                         statistics.map((stat) => <OfferStatistics key={stat.offer_id} offerData={stat} />)
//                     ) : (
//                         <p>Нет данных для отображения.</p>
//                     )
//                 )}
//             </div>

//             {/* Передаем startDate и endDate в PartnerStatisticsTable */}
//             <PartnerStatisticsTable partnerId="some_partner_id" startDate={startDate} endDate={endDate} />
//         </div>
//     );
// };

// export default PartnerStatistics;

// PartnerStatistics.js
import React, { useContext, useEffect, useCallback, useState } from 'react';
import axios from 'axios';
import { DateContext } from '../DateContext';  // Импортируем контекст
import OfferStatistics from './OfferStatistics';
import PartnerStatisticsTable from './PartnerStatisticsTable'; // Подключите таблицу
import s from '../styles/PartnerStatistics.module.css';

const PartnerStatistics = () => {
  const { startDate, endDate, setStartDate, setEndDate } = useContext(DateContext); // Получаем доступ к датам из контекста
  const [statistics, setStatistics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchStatistics = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const params = { start_date: startDate, end_date: endDate };
      const response = await axios.get('/api/partner/statistics', { params });
      setStatistics(response.data.offers);
    } catch (err) {
      setError('Не удалось загрузить статистику. Пожалуйста, попробуйте снова.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate]);

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchStatistics();
  };

  useEffect(() => {
    fetchStatistics();
  }, [fetchStatistics]);

  return (
    <div className={s.partner_statistics}>
      <form onSubmit={handleSubmit}>
        <div>
          <label>От: </label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div>
          <label>До: </label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Загрузка...' : 'Обновить'}
        </button>
      </form>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      <div className={s.statistics_container}>
        {loading ? (
          <p>Загрузка данных...</p>
        ) : (
          statistics.length > 0 ? (
            statistics.map((stat) => <OfferStatistics key={stat.offer_id} offerData={stat} />)
          ) : (
            <p>Нет данных для отображения.</p>
          )
        )}
      </div>

     
    </div>
  );
};

export default PartnerStatistics;
