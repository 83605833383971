import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import s from '../styles/login.module.css'; 
import { useTranslation } from 'react-i18next';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { fetchCurrentUser } = useContext(AuthContext); // Получаем функцию обновления пользователя
  const { t } = useTranslation();

  const handleGoogleLogin = useCallback(async (response) => {
    try {
      const { credential } = response;
      const googleResponse = await fetch('/api/googlelogin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id_token: credential }), // Отправляем id_token от Google на сервер
      });

      if (!googleResponse.ok) {
        const data = await googleResponse.json();
        setError(data.error || 'Google login failed');
        return;
      }

      // Успешный вход через Google
      const data = await googleResponse.json();
      console.log('Google login successful:', data);

      // Обновляем данные текущего пользователя после входа через Google
      await fetchCurrentUser();

      // Перенаправляем пользователя
      navigate('/partner');
    } catch (err) {
      setError('Error logging in with Google');
    }
  }, [fetchCurrentUser, navigate]);

  useEffect(() => {
    /* Загружаем Google Identity Services при монтировании компонента */
    window.google.accounts.id.initialize({
      client_id: '198052222787-lqhgm4ieau00nk9n2tbpjb3pib9rs3e9.apps.googleusercontent.com', // Замените на ваш клиентский ID
      callback: handleGoogleLogin,
    });

    window.google.accounts.id.renderButton(
      document.getElementById('googleSignInDiv'),
      { theme: 'outline', size: 'large' }
    );
  }, [handleGoogleLogin]);

  const handleLogin = async (e) => {
    e.preventDefault(); // Предотвращаем отправку формы
    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        const data = await response.json();
        setError(data.error || 'Login failed');
        return;
      }

      // Если запрос успешен
      const data = await response.json();
      console.log('Login successful:', data);

      // Обновляем данные текущего пользователя
      await fetchCurrentUser();

      // Перенаправляем пользователя
      navigate('/partner');
    } catch (err) {
      setError('Error logging in');
    }
  };

  return (
    <div>
      <h1 className='all_str_h1'>{t("Login")}</h1>
      <div className={s.login_container}>
        <div className={s.login_form}>
          <form onSubmit={handleLogin}>
            <input
              type="email"
              placeholder={t("Email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
              required
            />
            <input
              type="password"
              placeholder={t("Password")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              autoComplete="current-password"
            />
            <div className={s.link}>
              <Link style={{ color: 'white' }}>{t("fogot_pass")}</Link>
            </div>
            <button type="submit">{t("Sign-in")}</button>
            {error && <p>{error}</p>}
          </form>

          {/* Кнопка входа через Google */}
          <div id="googleSignInDiv" className={s.google_sign_in}></div>
        </div>
      </div>
    </div>
  );
}

export default Login;
