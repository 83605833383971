// DateContext.js
import React, { createContext, useState } from 'react';
import dayjs from 'dayjs';

// Создаем контекст
export const DateContext = createContext();

// Провайдер контекста для хранения и управления датами
export const DateProvider = ({ children }) => {
  // Инициализируем даты последних 30 дней
  const [startDate, setStartDate] = useState(dayjs().subtract(30, 'day').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));

  return (
    <DateContext.Provider value={{ startDate, endDate, setStartDate, setEndDate }}>
      {children}
    </DateContext.Provider>
  );
};
